
import { defineComponent, onMounted, onUnmounted, PropType, ref } from 'vue'
import Prodotti from "@/types/prodotti";
import * as Helpers from "@/helpers/scroll-categorie";

export default defineComponent({
	name: 'MenuCategorie',
	props: {
		prodottiXcats: { type: Object as PropType<Prodotti>, required: true }
	},
	setup() {
		const catAttiva = ref(0);

		// al click su una voce di menù, imposta la cat attiva, la centra nel menù
		// e va alla posizione della pagina relativa
		const attiva = (index: number) => {
			catAttiva.value = index;
			// document.getElementById(id)?.scrollIntoView({behavior: 'smooth', inline: 'center'})
			Helpers.centerActiveCat(index)
			Helpers.goToCat(index)
		}

		// quando si effettua lo scroll, imposta la categoria attiva
		// con quella relativa alla view
		function scrollHandler(e: Event) {
			const maybeNewIndex = Helpers.getIndexCatActive()
			if(maybeNewIndex != catAttiva.value) {
				catAttiva.value = maybeNewIndex
			}
		}

		// gestione evento scroll
		let handleDebouncedScroll: EventListenerOrEventListenerObject;

		onMounted(() => {
			handleDebouncedScroll = Helpers.debounce(scrollHandler, 100);
			window.addEventListener('scroll', handleDebouncedScroll);
		})

		onUnmounted(() => {
			window.removeEventListener('scroll', handleDebouncedScroll);
		})

		return { attiva, catAttiva }
	},
})
