import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4985df8e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bg-gray-100" }
const _hoisted_2 = { class: "max-w-7xl mx-auto px-2 sm:px-6 lg:px-8" }
const _hoisted_3 = {
  id: "lista-categorie",
  class: "relative flex items-center h-12 overflow-x-auto flex-nowrap"
}
const _hoisted_4 = ["id"]
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.prodottiXcats, (prodotti, cat, index) => {
          return (_openBlock(), _createElementBlock("li", {
            key: cat,
            id: `menuCat${index}`,
            class: "flex-none mx-3 text-sm"
          }, [
            _createElementVNode("button", {
              type: "button",
              onClick: ($event: any) => (_ctx.attiva(index)),
              class: _normalizeClass([_ctx.catAttiva == index ? 'bg-green-600 text-white px-2 py-1 rounded-md text-sm font-medium hover:bg-green-700' : ''])
            }, _toDisplayString(cat), 11, _hoisted_5)
          ], 8, _hoisted_4))
        }), 128))
      ])
    ])
  ]))
}