
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import CartModal from '@/components/CartModal.vue'
import ProductItem from '@/components/ProductItem.vue';
import { useStore } from '@/store';
import { Icon, Popover, Cell, CountDown, Search } from 'vant';
import { initApi } from "@/services/api";
import { initUpdateAdesso, isOrdiniAperti, scadenzaTimer, giornoScadenza } from "@/services/timerOrdini";
import MenuCategorie from "@/components/MenuCategorie.vue";
import Prodotti from "@/types/prodotti";

export default defineComponent({
	name: 'Home',
	setup() {
		const store = useStore();

		const authIsReady = computed(() => store.state.authIsReady);

		onMounted(() => {
			console.log('home mounted');
			if(authIsReady.value) {
				initApi();
			}
		});

		// appena è pronto auth, get prodotti
		watch(authIsReady, (currVal, prevVal) => {
			if(currVal && !prevVal && store.state.user) {
				initApi();
			}
		});

		const cartModal = ref<InstanceType<typeof CartModal>>()
		const openCartModal = () => {
			cartModal.value?.toggleModal()
		}

		const showPopover = ref(false);

		const adesso = ref(Date.now());
		initUpdateAdesso(adesso);

		const termineRicerca = ref('');

		watch(termineRicerca, (termine, prev) => window.scroll({top:0}));

		const prodottiXcatsFiltrati = () => {
			const pXc = store.state.prodotti
			const search = termineRicerca.value.trim().toLowerCase()
			const filtrati: Prodotti = {}
			for (const cat in pXc) {
				const prodotti = pXc[cat].filter(prod => prod.disponibile && prod.nome.toLowerCase().includes(search))
				if(prodotti.length) {
					filtrati[cat] = prodotti
				}
			}
			return filtrati
		}

		const isMobile = ref(window.innerWidth < 640);
		window.addEventListener('resize', () => isMobile.value = window.innerWidth < 640);

		return {
			cartModal, openCartModal,
			user: computed(() => store.state.user),
			authIsReady,
			prodottiXcats: computed(prodottiXcatsFiltrati),
			prodottiInCart: computed(() => Object.keys(store.state.cart).length),
			cliente: computed(() => store.state.cliente),
			showPopover,
			logout: () => store.dispatch('logout'),
			ordiniAperti: computed(() => isOrdiniAperti(adesso)),
			countdown: computed(() => scadenzaTimer(adesso)),
			giornoScadenza: computed(() => giornoScadenza(adesso)),
			termineRicerca,
			isMobile
		}
	},
	components: {
		CartModal,
		ProductItem,
		Icon,
		Popover,
		Cell,
		CountDown,
		MenuCategorie,
		Search
	}
});
