/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
	register(`${process.env.BASE_URL}service-worker.js`, {
		ready () {
			console.log(
			'App is being served from cache by a service worker.\n' +
			'For more details, visit https://goo.gl/AFskqB'
			)
		},
		registered ( registration ) {
			console.log('Service worker has been registered.', new Date)
			// ogni minuto vedi se è disponibile un aggiornamento dell'app
			setInterval(() => {
				// The update() method of the ServiceWorkerRegistration interface attempts to update the service worker.
				// It fetches the worker's script URL, and if the new worker is not byte-by-byte identical to the current worker, it installs the new worker.
				// The fetch of the worker bypasses any browser caches if the previous fetch occurred over 24 hours ago.
				console.log('registration.update() called', new Date);
				registration.update();
			}, 30*1000);
		},
		cached () {
			console.log('Content has been cached for offline use.')
		},
		updatefound () {
			console.log('New content is downloading.')
		},
		updated ( registration ) {
			console.log('New content is available; please refresh.')
			document.dispatchEvent(
				new CustomEvent('swUpdated', { detail: registration })
			)
		},
		offline () {
			console.log('No internet connection found. App is running in offline mode.')
		},
		error (error) {
			console.error('Error during service worker registration:', error)
		}
	})
}
