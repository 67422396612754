
import { defineComponent, ref, computed } from 'vue';
import { useStore } from "@/store";
import { prepareVerifier, login, confirmCodeSms } from "@/services/auth";

export default defineComponent({
	name: 'Login',
	setup() {
		const phoneNumber = ref('');
		const authcode = ref('');
		const store = useStore();

		const isPhoneNumOk = computed(() => {
			const phoneNum = phoneNumber.value.trim();
			return phoneNum.match(/^(([+]|00)39)?((3[1-6][0-9]))(\d{7})$/g);
		})

		const sendingOtp = ref(false);

		const sendOtp = async () => {
			sendingOtp.value = true;
			console.log('send otp', phoneNumber);
			const phoneNum = phoneNumber.value.trim();
			if(!isPhoneNumOk) {
				console.error('Numero di telefono non valido:', phoneNum);
				return;
			}
			prepareVerifier('sign-in-button');
			if(await login(phoneNum)) {
				toggleModal();
				sendingOtp.value = false;
			}
		}

		const toggleModal = () => {
			const body = document.querySelector('body')
			const modal = document.getElementById('authcode-modal')
			modal && ['opacity-0', 'pointer-events-none'].map(v => modal.classList.toggle(v))
			body?.classList.toggle('modal-active')
		}

		const confirm = () => {
			console.log(authcode.value);
			if( authcode.value ) {
				confirmCodeSms(authcode.value);
			}
		}

		return {
			phoneNumber, sendOtp, confirm, authcode, isPhoneNumOk, sendingOtp,
			user: computed(() => store.state.user),
			authIsReady: computed(() => store.state.authIsReady),
			toggleModal
		}
	}
});
