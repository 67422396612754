// The debounce() function forces a function to wait a certain amount of time before
// running again. The function is built to limit the number of times a function is called.
export function debounce (callback: CallableFunction, wait: number) {
	let timeoutId: number;
	return (...args: any) => {
		window.clearTimeout(timeoutId);
		timeoutId = window.setTimeout(() => callback(args), wait);
	};
}

// centra la voce di menù attiva
export function centerActiveCat(i: number) {
	const ulCats   = document.getElementById('lista-categorie');
	const liActive = ulCats?.querySelectorAll('li')[i];
	const liPos    = liActive?.getBoundingClientRect().left;
	if(ulCats && liActive && liPos) {
		const newLiPos = ulCats.offsetWidth / 2 - liActive.offsetWidth / 2;
		const marginLeft = parseInt(window.getComputedStyle(document.body).getPropertyValue('margin-left'));
		ulCats.scrollLeft += liPos - newLiPos - marginLeft;
	}
}

import { windowScroll } from "seamless-scroll-polyfill";
// vai alla posizione della pagina relativa alla categoria selezionata
export function goToCat(i: number) {
	const yOffset = document.getElementById(`cat${i}`)?.offsetTop;
	if(!yOffset) {
		return;
	}
	// document.getElementById(`cat${i}`)?.scrollIntoView({behavior: 'smooth'});
	const headerHeight = document.getElementById('header-wrap')?.clientHeight ?? 0;
	// window.scrollTo({top: yOffset - headerHeight + 1, left: 0, behavior: 'smooth'});
	windowScroll(window, {top: yOffset - headerHeight + 1, left: 0, behavior: 'smooth'}, {duration: 1000});
}

// ottiene la categoria relativa alla attuale posizione nella pagina
export function getIndexCatActive() {
	const posBottomMenu = document.getElementById('header-wrap')?.getBoundingClientRect().bottom;
	if(!posBottomMenu) {
		return 0;
	}
	const cats = document.getElementsByClassName('cat-header');
	let newIndexCatActive = 0;
	for(let i=0; i < cats.length; i++) {
		const posTopEl = cats[i].getBoundingClientRect().top;
		if(posTopEl > posBottomMenu) {
			break;
		}
		newIndexCatActive = i;
	}
	return newIndexCatActive;
}