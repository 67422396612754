import axios from "axios";
import { store } from '@/store';
import { getIdToken } from "firebase/auth";
// import Prodotto from '@/types/prodotto';
import { notify } from "@kyvg/vue3-notification";

const API = axios.create({
	baseURL: process.env.VUE_APP_API_URL,
	headers: {}
})

const clienteEsiste = async (num_tel: string) => {
	const res = await API.post('cliente-esiste', {tel: num_tel});
	return !!res.data;
}

function maybeLogout( err: any ) {
	if(err?.response?.status == 401) {
		store.dispatch('logout');
		notify({group: 'su', type: 'error', text: 'Utente non autorizzato'});
	}
}

const getAll = async () => {
	console.log('getAll() called');
	if( !store.state.user ) {
		return;
	}
	const idToken = await getIdToken(store.state.user);
	// console.log(store.state.user, idToken);
	try {
		const res = await API.post('all', {token: idToken});
		console.log('api all results', res.data);
		if( !Array.isArray(res.data) || res.data.length != 2 ) {
			throw new Error('Dati API getAll non corretti');
		}
		store.commit('setCliente', res.data[0]);
		const prodotti = setPrezzi(res.data[1], res.data[0]);
		console.log(prodotti);
		store.commit('setProdotti', prodotti);
	} catch (err) {
		console.error(err);
		maybeLogout(err);
	}
}

function setPrezzi(prodotti: any, cliente: any) {
	const listino = cliente[6];
	for (const cat in prodotti) {
		for (const prod in prodotti[cat]) {
			const prezzi = prodotti[cat][prod]['prezzi'];
			prodotti[cat][prod]['prezzo'] = prezzi[listino];
			delete prodotti[cat][prod]['prezzi'];
		}
	}
	return prodotti;
}

// const getChecksum = () => {
// 	API.get('checksum').then(res => {
// 		store.commit('setChecksum', res.data);
// 	}).catch(e => console.error(e));
// }

let intervalID: number;

function setTimerUpdate() {
	intervalID && clearInterval(intervalID);
	intervalID = setInterval(() => {
		maybeUpdateProdotti();
		maybeUpdateGgChiusura();
	}, 1 * 60 * 1000);
	console.log('timer aggiornamenti impostato');
}


const postOrdine = async () => {
	if( !store.state.user ) {
		return;
	}
	const idToken = await getIdToken(store.state.user);
	// console.log(store.state.user, idToken);
	return API.post('ordine', {
		token: idToken,
		cart: store.state.cart
	});
}

const maybeUpdateProdotti = async () => {
	try {
		const res = await API.get('checksum');
		if(res.data != store.state.checksum) {
			console.log('aggiornamento prodotti');
			getAll();
			store.commit('setChecksum', res.data);
			// se carrello non vuoi, mostra notifica
			if(Object.keys(store.state.cart).length) {
				notify({
					group: "su",
					type: "warning",
					title: 'Prodotti aggiornati',
					text: 'Alcuni prodotti presenti in carrello potrebbero aver subito variazioni'
				});
			}
		}
	} catch (err) {
		console.error('Errore nel recupero del checksum', err);
	}
}

const maybeUpdateGgChiusura = async () => {
	try {
		const res = await API.get('checksum-ggChiusura');
		if(res.data != store.state.checksum_ggChiusura) {
			console.log('aggiornamento giorni chiusura');
			getGgChiusura();
			store.commit('setChecksumGgChiusura', res.data);
		}
	} catch (err) {
		console.error('Errore nel recupero del checksum', err);
	}
}

const getGgChiusura = async () => {
	try {
		const res = await API.get('giorni-chiusura');
		const oggi = (new Date).toLocaleDateString('en-CA'); // YYY-MM-DD
		const giorni = res.data.filter((x: string) => oggi <= x);
		store.commit('setGgChiusura', giorni);
	} catch (err) {
		console.error('Errore nel recupero dei giorni di chiusura', err);
	}
}

const initApi = () => {
	// getAll();
	// getChecksum();
	maybeUpdateProdotti();
	maybeUpdateGgChiusura();
	setTimerUpdate();
}

const haltApi = () => {
	clearInterval(intervalID);
}

export { postOrdine, initApi, haltApi, clienteEsiste }