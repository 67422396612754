import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: "AIzaSyCgGRIDW2DFRmi9i8Ca59omNmQ4W4GhBE4",
	authDomain: "verdura-vue.firebaseapp.com",
	projectId: "verdura-vue",
	storageBucket: "verdura-vue.appspot.com",
	messagingSenderId: "1075176143864",
	appId: "1:1075176143864:web:57ec772daf43febc6bb40d",
	measurementId: "G-8E6RTT2W0D"
};

// init firebase
initializeApp(firebaseConfig)

// init firebase auth
const auth = getAuth()
auth.languageCode = 'it';

export { auth }