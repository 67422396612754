import { InjectionKey } from 'vue'
import { createStore, useStore as baseUseStore, Store } from 'vuex'
// firebase imports
import { auth } from '@/firebase/config'
import { signOut, onAuthStateChanged, User } from 'firebase/auth'
import router from '@/router'
import Prodotti from '@/types/prodotti'
import Carrello from '@/types/carrello'
import { notify } from '@kyvg/vue3-notification';
import { haltApi } from "@/services/api";

// define your typings for the store state
interface State {
	user: User | null,
	authIsReady: boolean,
	prodotti: Prodotti,
	cliente: string[] | null,
	cart: Carrello,
	checksum: string,
	checksum_ggChiusura: string,
	ggChiusura: string[]
}

// define injection key
const key: InjectionKey<Store<State>> = Symbol();

// create store
const store = createStore<State>({
	state: {
		user: null,
		authIsReady: false,
		prodotti: {},
		cliente: null,
		cart: {},
		checksum: '',
		checksum_ggChiusura: '',
		ggChiusura: []
	},
	mutations: {
		initialiseStore(state) {
			// Check if the ID exists
			const json = localStorage.getItem('store');
			if(json) {
				// Replace the state object with the stored item
				this.replaceState(
					Object.assign(state, JSON.parse(json))
				);
				state.authIsReady = false;
			}
			console.log('store initialized');
		},
		setUser(state, payload) {
			state.user = payload
			console.log('user state changed:', state.user)
		},
		setAuthIsReady(state, payload) {
			state.authIsReady = payload
		},
		setProdotti(state, payload) {
			state.prodotti = payload
			console.log('prodotti state changed:', state.prodotti)
		},
		setCliente(state, payload) {
			state.cliente = payload
			console.log('cliente state changed:', state.cliente)
		},
		setCart(state, payload) {
			state.cart = payload
			console.log('cart state changed:', state.cart)
		},
		setChecksum(state, payload) {
			state.checksum = payload
			console.log('checksum state changed:', state.checksum)
		},
		setChecksumGgChiusura(state, payload) {
			state.checksum_ggChiusura = payload
			console.log('checksum giorni chiusura state changed:', state.checksum_ggChiusura)
		},
		setGgChiusura(state, payload) {
			state.ggChiusura = payload
			console.log('giorni chiusura state changed:', state.ggChiusura)
		},
	},
	actions: {
		login(context, user: User) {
			console.log('login action')
			context.commit('setUser', user);
			router.push('/');
		},
		async logout(context) {
			console.log('logout action')
			await signOut(auth)
			context.commit('setUser', null)
			haltApi();
			router.push('/login')
		},
		add2cart({ commit, state }, prod: {id: number, qta: number}) {
			const cart = state.cart;
			if( cart.hasOwnProperty(prod.id) ) {
				cart[prod.id] += prod.qta;
			} else {
				cart[prod.id] = prod.qta;
			}
			commit('setCart', cart);
			// notify({group: 'su', type: 'success', text: 'Prodotto aggiunto al carrello'});
		},
		removeFromCart({ commit, state }, id_prod: number) {
			const cart = state.cart;
			delete cart[id_prod];
			commit('setCart', cart);
			notify({group: 'su', type: 'success', text: 'Prodotto rimosso dal carrello'});
		},
		emptyCart() {
			this.commit('setCart', {});
		},
		setProdQta({commit, state}, prod: {id: number, qta: number}) {
			const cart = state.cart;
			cart[prod.id] = prod.qta;
			commit('setCart', cart);
			notify({group: 'su', type: 'success', text: 'Quantità del prodotto modificata'});
		}
	},
	getters: {
		isClienteAdmin: state => Array.isArray(state.cliente) && state.cliente.length >= 9 && !!state.cliente[8]
	}
})

// wait until auth is ready
const unsub = onAuthStateChanged(auth, (user) => {
	console.log('auth is ready');
	store.commit('setAuthIsReady', true)
	store.commit('setUser', user)
	unsub()
	if(!user) {
		router.push('/login')
	} else {
		router.push('/')
	}
})

// Subscribe to store updates
store.subscribe((mutation, state) => {
	// Store the state object as a JSON string
	localStorage.setItem('store', JSON.stringify(state));
	console.log('store saved in localStorage');
});


// define your own `useStore` composition function
const useStore = () => {
	return baseUseStore(key)
}

// export the store
export { store, useStore, key }