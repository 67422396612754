
import { defineComponent, PropType, ref, computed } from 'vue';
import Prodotto from "@/types/prodotto";
import { Stepper } from 'vant';
import { useStore } from '@/store';

export default defineComponent({
	name: 'ProductItem',
	setup(props) {
		const qta = ref<number>(1)
		const store = useStore()

		const prezzo = computed(() => new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(props.prodotto.prezzo).replace('€', '').trim())

		let stato_btn = ref('');

		const add2cart = () => {
			stato_btn.value = 'loading';
			setTimeout(() => {
				stato_btn.value = 'done';
				setTimeout(() => stato_btn.value = '', 800);
			}, 500);
			store.dispatch('add2cart', {id: props.prodotto.id, qta: qta.value});
		}
		// const add2cart = () => store.dispatch('emptyCart')

		return { qta, prezzo, add2cart, stato_btn }
	},
	props: {
		prodotto: {
			required: true,
			type: Object as PropType<Prodotto>
		}
	},
	components: {
		Stepper
	}
});
