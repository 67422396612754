
import { computed, defineComponent, ref } from 'vue';
import { useStore } from '@/store';
import RigaCart from "@/types/rigaCart";
import { Stepper, Dialog, Icon } from 'vant';
import { postOrdine } from "@/services/api";
import { notify } from "@kyvg/vue3-notification";

export default defineComponent({
	name: 'CartModal',
	props: {
		giornoScadenza: { type: Date, required: true },
		ordiniAperti: { type: Boolean, required: true }
	},
	setup(props) {
		const toggleModal = () => {
			const body = document.querySelector('body')
			const modal = document.getElementById('cart-modal')
			modal && ['opacity-0', 'pointer-events-none'].map(v => modal.classList.toggle(v))
			body?.classList.toggle('modal-active')
		}

		const store = useStore()

		const righe = computed(() => {
			const rows: RigaCart[] = [];
			for(const id_prod in store.state.cart) {
				const prod = getProdotto(parseInt(id_prod));
				if(prod) {
					rows.push({qta: store.state.cart[id_prod], prod: prod});
				}
			}
			return rows;
		});

		function getProdotto( id: number ) {
			for(const i in store.state.prodotti) {
				for(const j in store.state.prodotti[i]) {
					if(store.state.prodotti[i][j].id == id) {
						return store.state.prodotti[i][j];
					}
				}
			}
			return false;
		}

		const updateQta = (val: number, obj: {name: string}) => {
			// console.log('updateQta', obj);
			store.dispatch('setProdQta', {id: obj.name.substr(4), qta: val});
		}

		// Visto che non si può passare l'oggetto riga all'evento before-change dello Stepper,
		// maybeDelete() salva in zeroValClick se si è provato ad azzerare la quantità,
		// e poi click() lo recupera e in caso chiama maybeRimuoviRiga()

		let zeroValClick = false;

		const maybeDelete = (val: number) => {
			if(!val) {
				zeroValClick = true;
				return false;
			}
			return true;
		}

		const click = (id_prod: number) => {
			setTimeout(() => {
				if(zeroValClick) {
					maybeRimuoviRiga(id_prod);
					zeroValClick = false;
				}
			}, 50);
		}

		const maybeRimuoviRiga = (id_prod: number) => {
			Dialog.confirm({
				title: 'Sicuro?',
				message: 'Procedendo il prodotto verrà rimosso dal carrello',
				cancelButtonText: 'Annulla',
				confirmButtonText: 'Rimuovi'
			}).then(() => {
				store.dispatch('removeFromCart', id_prod);
			});
		}

		let ordineInInvio = ref(false);

		const inviaOrdine = async () => {
			try {
				await Dialog.confirm({
					title: 'Sei sicuro?',
					message: "Procedendo verrà effettuato l'ordine",
					cancelButtonText: 'Annulla',
					confirmButtonText: 'Invia'
				});
			} catch (err) {
				return;
			}
			ordineInInvio.value = true;
			let res;
			try {
				res = await postOrdine();
				if(res && res.data) {
					store.dispatch('emptyCart');
					toggleModal();
					notify({
						group: "su",
						type: "success",
						title: 'Tutto ok',
						text: 'Ordine effettuato con successo!'
					});
				}
			} catch (err: any) {
				notify({
					group: "su",
					type: "error",
					title: 'Errore',
					text: err
				});
			}
			ordineInInvio.value = false;
		}

		return {
			toggleModal, righe,
			prezzo: computed(() => righe.value.reduce((acc, riga) => acc + (riga.qta * riga.prod.prezzo), 0)),
			updateQta,
			giornoFormattato: computed(() => props.giornoScadenza.toLocaleString('it-IT', { year: 'numeric', month: 'short', day: 'numeric' })),
			maybeDelete, click, inviaOrdine, ordineInInvio, maybeRimuoviRiga,
			isAdmin: computed(() => store.getters.isClienteAdmin)
		}
	},
	components: {
		Stepper,
		Icon
	}
});
