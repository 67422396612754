
import { defineComponent } from 'vue';
import { useStore } from '@/store';
import listen_for_app_update from "@/services/sw-update";

export default defineComponent({
	beforeCreate() {
		const store = useStore();
		store.commit('initialiseStore');
	},
	setup() {
		listen_for_app_update();
		// return {}
	}
})
