import { auth } from '@/firebase/config';
import { ConfirmationResult, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { store } from "@/store";
import { Notify } from "vant";
import { clienteEsiste } from "@/services/api";

let recaptcha: RecaptchaVerifier;
let confirmationRes: ConfirmationResult;

const prepareVerifier = (idHtmlBtn: string) => {
	recaptcha = new RecaptchaVerifier(idHtmlBtn, {
		'size': 'invisible',
		'callback': (response: any) => {
			// reCAPTCHA solved, allow signInWithPhoneNumber.
			// signInWithPhoneNumber();
			console.log('recaptcha solved', response);
		},
		// 'expired-callback': () => {},
		// 'error-callback': () => {},
	}, auth);
}

const login = async (phoneNum: string) => {
	phoneNum = '+39' + phoneNum.substring(phoneNum.length - 10);
	try {
		const res = await signInWithPhoneNumber(auth, phoneNum, recaptcha);
		// SMS sent. Prompt user to type the code from the message, then sign the
		// user in with confirmationResult.confirm(code).
		confirmationRes = res;
		console.log(res);
		return true;
	} catch (error) {
		Notify({type: 'danger', message: `${error}`});
		console.error(error);
	}
	return false;
}

const confirmCodeSms = async (codeSms: string) => {
	try {
		const res = await confirmationRes.confirm(codeSms);
		// User signed in successfully.
		console.log(res);
		if(res.user.phoneNumber && await clienteEsiste(res.user.phoneNumber)) {
			store.dispatch('login', res.user);
		} else {
			throw new Error('Utente non autorizzato!');
		}
	} catch (error) {
		// User couldn't sign in (bad verification code?)
		Notify({type: 'danger', message: `${error}`});
		console.error(error);
	}
}

export { prepareVerifier, login, confirmCodeSms }