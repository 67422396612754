import { Ref } from 'vue';
import { store } from '@/store';

const oraChiusuraOrdini = 3;
const oraAperturaOrdini = 7;

function initUpdateAdesso(adesso: Ref<number>) {
	adesso.value = Date.now()
	// console.log('adesso aggiornato', adesso.value);
	const data = new Date;
	const secondiInMillesimi = data.getSeconds() * 1000 + data.getMilliseconds();
	const mancano = 60000 - secondiInMillesimi;
	// console.log('secondi in millesimi: ', secondiInMillesimi, ' - mancano: ', mancano)
	setTimeout(initUpdateAdesso, mancano, adesso)
}

function isOrdiniAperti(adesso: Ref<number>) {
	return !!statoOrdini(adesso.value)
}

function scadenzaTimer(adesso: Ref<number>) {
	const stato = statoOrdini(adesso.value)
	if(!stato) { // scaduto
		return 0;
	}
	const data = new Date(adesso.value)
	if(stato === 1) { // scadenza domani
		data.setDate(data.getDate() + 1)
	}
	const chiusura = data.setHours(oraChiusuraOrdini, 0, 0, 0)
	return chiusura - adesso.value
}

function giornoScadenza(adesso: Ref<number>) {
	const stato = statoOrdini(adesso.value)
	const data = new Date(adesso.value)
	data.setHours(oraChiusuraOrdini, 0, 0, 0)
	if(stato >= 0) { // domani
		data.setDate(data.getDate() + 1)
	}
	return data
}

/**
 * Ritorna -1 se l'orario attuale è precedente la scadenza odierna,
 * 0 se è scaduto e devono ancora riaprire gli ordini,
 * 1 se la scadenza è domani
 *
 * @param timestamp: number
 * @returns -1|0|1
 */
function statoOrdini(timestamp: number) {
	const now = new Date(timestamp);
	// controlla se è un giorno di chiusura
	for (const g of store.state.ggChiusura) {
		const riapertura = new Date(g);
		riapertura.setHours(oraAperturaOrdini);
		const chiusura = new Date(riapertura);
		chiusura.setDate(chiusura.getDate() - 1);
		// console.log(chiusura, riapertura);
		if(chiusura <= now && now <= riapertura) {
			return 0;
		}
	}
	// altrimenti controlla se è un orario
	// quotidiano di chiusura ordini
	const dataApertura = new Date(now);
	const dataChiusura = new Date(now);
	dataChiusura.setHours(oraChiusuraOrdini, 0, 0, 0);
	dataApertura.setHours(oraAperturaOrdini, 0, 0, 0);
	if(now < dataChiusura) { // scadenza oggi
		return -1;
	} else if(now >= dataChiusura && now < dataApertura) { // scaduto
		return 0;
	} else if(now >= dataApertura) { // scadenza domani
		return 1;
	}
	return 0; // in teoria non dovrebbe mai arrivare qui
}

export { initUpdateAdesso, isOrdiniAperti, scadenzaTimer, giornoScadenza }